
import { defineComponent, nextTick } from 'vue';
import AgePickerComponent from './_component.vue';
import FormInput from '@/components/form/Input/index.vue';

export default defineComponent({
  name: 'form-age-picker-range',
  emits: ['update:modelValue', 'update'],
  props: {
    fromValue: {
      type: String,
      default: '',
    },
    toValue: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      from: this.fromValue,
      to: this.toValue,
      focusFrom: true,
      fromPicker: '',
      toPicker: '',
      visible: false,
      value: this.modelValue,
    };
  },
  computed: {
    fromItems() {
      const items = [];

      for (let i = 14; i <= parseInt(this.toPicker); i++) {
        items.push(i);
      }

      return items;
    },
    toItems() {
      const items = [];

      for (let i = parseInt(this.fromPicker); i <= 100; i++) {
        items.push(i);
      }

      return items;
    },
    rangeTime(): string[] {
      const value = this.modelValue.replace(/\s/g, '');

      return value.split('-');
    },
    modifyValue(): { from?: string; to?: string } {
      const value = this.value.replace(/\s/g, '');

      if (value.length > 4) {
        const result = value.split('-');

        return {
          from: result[0],
          to: result[1],
        };
      }

      return {};
    },
  },
  methods: {
    documentClick(e: { target: EventTarget | null }) {
      const el = this.$refs.agePickerRange as HTMLElement;
      const target = e.target;

      if (this.visible && el && el !== target && !el.contains(target as Node)) {
        this.visible = false;
      }
    },
    save() {
      this.value = `${this.from}-${this.to}`;
      const range = this.value.split('-');

      this.$emit('update:modelValue', this.value);
      this.$emit('update', {
        value: this.value,
        from: range[0],
        to: range[1],
      });

      this.visible = false;
    },
    handlerSlot(focusFrom: boolean) {
      this.visible = true;
      this.focusFrom = focusFrom;

      nextTick(() => {
        const from: HTMLInputElement = document.querySelector('.age-picker-range__item_from input') as HTMLInputElement;
        const to: HTMLInputElement = document.querySelector('.age-picker-range__item_to input') as HTMLInputElement;

        if (focusFrom) {
          from.select();

          return;
        }

        to.select();
      });
    },
    handlerFocus(event: { target: HTMLInputElement }, focusFrom: boolean) {
      this.focusFrom = focusFrom;

      event.target.select();
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.from = this.rangeTime[0];
        this.to = this.rangeTime[1];
      },
    },
    fromPicker() {
      this.from = this.fromPicker;
    },
    toPicker() {
      this.to = this.toPicker;
    },
    visible(value: boolean) {
      if (value) {
        this.from = this.rangeTime[0] ?? '';
        this.to = this.rangeTime[1] ?? '';
      }
    },
  },
  mounted() {
    document.body.addEventListener('click', this.documentClick);
  },
  components: {
    AgePickerComponent,
    FormInput,
  },
});


import { Prop, Component, Ref } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormAgePickerRange from '@/components/form/AgePicker/Range.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';

import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';

import CustomersTemplatesEntityModule, { TextFieldsData, DEFAULT_HOURS } from '@/store/customers/templatesEntity';
import { strings } from '@/lib/stringConst';
import { getServiceTypeAll, getUnitTypeAll } from '@/api/templates';
import { gendersAll } from '@/lib/Genders';
import { getAllVaccinationStatusList } from '@/api/templates';
import Loading from '@/components/Loading.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { TemplateModel } from '@/interfaces/models/template.interface';
import { getHintOfRestrictionByHours } from '@/lib/ShiftTemplate';
import { nextTick } from 'vue';
import { getCitizenshipsAll } from '@/api/citizenship';
import { BaseSelectItem } from '@/lib/formFactory/select.interface';
import { GuiFormRadio } from '@library/gigant_ui';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  emits: ['returnBefore'],
  components: {
    GuiFormRadio,
    Loading,
    FormInput,
    FormTextarea,
    FormSelect,
    FormAutocomplete,
    FormButton,
    FormAgePickerRange,
    FormMultiSelect,
    AppFieldset,
    AppRow,
    AppCol,
    IconFont,
    GuiFormCheckbox,
  },
})
export default class VacanciesFrom extends FormEditor {
  @Prop() typeAction!: string;

  @Ref('rateRef') rateRef: InstanceType<typeof FormInput> | null = null;
  @Ref('externalRateRef') externalRateRef: InstanceType<typeof FormInput> | null = null;

  readonly MAX_HOURS: number = +DEFAULT_HOURS;

  name = this.model.name ?? '';
  externalName = this.model.externalName ?? '';
  minHours = this.model.minHours ?? 0;
  maxHours: '' | number = this.model.maxHours ?? 0;
  hasLmk = this.model.hasLmk ?? false;
  isActive = this.model.isActive ?? false;
  isTraining = this.model.isTraining ?? false;
  trainingWebLink = this.model.trainingWebLink ?? '';
  info = this.model.info ?? '';
  forLk = this.model.forLk ?? false;
  isPreviouslyWorked = this.model.isPreviouslyWorked ?? false;

  rate = this.model.rate ?? '';
  initialRate = this.model.rate ?? '';
  isInvalidRate = false;

  externalRate = this.model.externalRate ?? '';
  initialExternalRate = this.model.externalRate ?? '';
  isInvalidExternalRate = false;

  serviceType = this.model.serviceType ?? { id: null, value: '' };
  vacancyItems: { id: string; value: string }[] = [];

  unitType = this.model.unitType ?? { id: null, value: '' };
  unitTypeItems: { id: number; value: string }[] = [];

  citizenship = this.model.citizenship;
  citizenshipModify = {};
  citizenshipItems: BaseSelectItem[] = [];

  vaccinationRequirement = this.model.vaccinationRequirement ?? 'not_vaccinated';
  vaccinationRequirementsItems: { id: string; value: string }[] = [];

  gender = this.model.gender ?? { id: 3, name: 'Не важно', value: '' };
  genderItems = gendersAll;

  listsLoading = true;
  isTemplateSaving = false;

  rules: Record<string, (value: string) => string | boolean> = {
    link: (value) => {
      if (!value.length) return true;

      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;

      return pattern.test(value) || 'Некорректная ссылка';
    },
    greatThanZero: (value) => {
      return !!this.isTraining || +value > 0 ? true : 'Значение должно быть больше нуля';
    },
    rate: (value) => {
      const isEmpty = !value.length;

      const isGreatExternalRate = +value > +this.externalRate;
      const validationGreatMessage = 'Ставка исполн. не может быть больше внешней ставки';

      const isInvalidCharacters = !/^\d+$/.test(value);
      const validationCharactersMessage = 'Допустимые символы: 0-9';

      switch (true) {
        case isEmpty:
          return true;
        case isGreatExternalRate:
          return validationGreatMessage;
        case isInvalidCharacters:
          return validationCharactersMessage;
        default:
          return true;
      }
    },
  };

  get normalizedMaxHours() {
    return this.maxHours === '' ? this.MAX_HOURS : this.maxHours;
  }

  get model(): TemplateModel {
    return CustomersTemplatesEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get errorMinHours(): string {
    return this.minHours > this.MAX_HOURS ? `${this.MAX_HOURS} максимальное значение` : '';
  }

  get responseData(): TextFieldsData {
    const result: TextFieldsData = {
      name: this.name,
      serviceType: this.serviceType.id as number,
      isActive: +this.isActive as number,
      rate: this.rate,
      minHours: this.minHours ?? 0,
      maxHours: this.normalizedMaxHours,
      isPreviouslyWorked: +this.isPreviouslyWorked as number,
      externalRate: this.externalRate,
      hasLmk: +this.hasLmk as number,
      info: this.info,
      externalName: this.externalName,
      isTraining: +this.isTraining as number,
      forLk: +this.forLk as number,
      vaccinationRequirement: this.vaccinationRequirement,
      educationLink: this.trainingWebLink,
      'age[from]': this.age.range[0],
      'age[to]': this.age.range[1],
      unitType: this.unitType.id as number,
      ...this.citizenshipModify,
    };

    if (this.gender && this.gender.value) {
      result['gender'] = this.gender.value;
    }

    return result;
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  async setCitizenshipItems(): Promise<void> {
    const citizenships = await getCitizenshipsAll();
    citizenships.sort((a, b) => a.sortOrder - b.sortOrder);

    this.citizenshipItems = citizenships.map(({ id, name }) => ({
      id: id.toString(),
      name,
      value: name,
    }));
  }

  async setVacancyItems(): Promise<void> {
    const result = await getServiceTypeAll();

    this.vacancyItems = result.map((item) => {
      return {
        id: item.id.toString(),
        value: item.name,
      };
    });
  }

  async setUnitTypeItems(): Promise<void> {
    const result = await getUnitTypeAll();

    this.unitTypeItems = result.map((item) => {
      return {
        id: item.id,
        value: item.name,
      };
    });
  }

  async setVaccinationStatusItems(): Promise<void> {
    const result = await getAllVaccinationStatusList();

    this.vaccinationRequirementsItems = result.map((item: { type: string; humanType: string }) => {
      if (this.model.vaccinationRequirement === item.type) {
        this.vaccinationRequirement = item.type;
      }

      return {
        id: item.type,
        value: item.humanType,
      };
    });
  }

  get age(): { value: string; range: string[] } {
    const value = `${this.model.ageFrom}-${this.model.ageTo}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  get hoursHint() {
    const min = this.minHours || 0;
    const max = this.normalizedMaxHours;

    return getHintOfRestrictionByHours(min, max);
  }

  async forceUpdateRate(...elementRefs: ('rateRef' | 'externalRateRef')[]) {
    elementRefs.forEach(async (elementRef) => {
      const ref = this[elementRef];

      if (!ref) {
        return;
      }

      const value = ref.modelValue.toString();

      ref.handler.update({ target: { value: '0' } });
      await nextTick();
      ref.handler.update({ target: { value } });
    });
  }

  async handleUpdateExternalRate() {
    const rate = this.rate;

    this.rateRef?.handler.update({ target: { value: '0' } });
    await nextTick();
    this.rateRef?.handler.update({ target: { value: rate.toString() } });
  }

  selectAge(value: { to: string; from: string }): void {
    this.model.ageFrom = value.from;
    this.model.ageTo = value.to;
  }

  async save() {
    try {
      if (this.isTemplateSaving) {
        return;
      }

      this.isTemplateSaving = true;
      await super.save();
    } finally {
      this.isTemplateSaving = false;
    }
  }

  async add(): Promise<void> {
    try {
      const data = this.responseData;
      await CustomersTemplatesEntityModule.addNew({
        customerId: this.$route.params.customerId as string,
        textFieldsData: data,
      });

      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async edit(): Promise<void> {
    try {
      const data = this.responseData;
      await CustomersTemplatesEntityModule.saveUpdate({
        id: this.$route.params.templateId as string,
        textFieldsData: data,
      });

      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async mounted(): Promise<void> {
    await this.setVacancyItems();
    await this.setUnitTypeItems();
    await this.setVaccinationStatusItems();
    await this.setCitizenshipItems();

    this.listsLoading = false;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "age-picker-component" }
const _hoisted_2 = { class: "age-picker-component__container" }
const _hoisted_3 = {
  key: 0,
  class: "age-picker-component__item age-picker-component__hours"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_VueScrollPicker = _resolveComponent("VueScrollPicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["age-picker-component__btn age-picker-component__btn_top", { disabled: _ctx.value === _ctx.items[0] }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollTop('items', 'value')))
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "cp-btn-add",
                icon: ['fas', 'chevron-left']
              })
            ], 2),
            _createVNode(_component_VueScrollPicker, {
              options: _ctx.items,
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
            }, null, 8, ["options", "modelValue"]),
            _createElementVNode("div", {
              class: _normalizeClass(["age-picker-component__btn age-picker-component__btn_bottom", { disabled: _ctx.value === _ctx.items[_ctx.items.length - 1] }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollBottom('items', 'value')))
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "cp-btn-add",
                icon: ['fas', 'chevron-left']
              })
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
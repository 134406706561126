
import { defineComponent } from 'vue';
import { VueScrollPicker } from 'vue-scroll-picker';
import './vue-scroll-picker.scss';

export default defineComponent({
  name: 'form-age-picker-component',
  emits: ['update:modelValue'],
  props: {
    defaultItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    current: {
      type: String,
    },
  },
  data() {
    return {
      items: this.defaultItems,
      value: this.current,
    };
  },
  methods: {
    initItems() {
      if (this.items.length) {
        return;
      }

      for (let i = 14; i <= 100; i++) {
        this.items.push(`${i}`);
      }
    },
    scrollTop(list: string, value: string) {
      let model: any = null;
      model = this;

      const currentIndex = model[list].findIndex((item: string) => {
        return item === model[value];
      });

      if (currentIndex <= 0) {
        return;
      }

      model[value] = model[list][currentIndex - 1] as string;
    },
    scrollBottom(list: string, value: string) {
      let model: any = null;
      model = this;

      const currentIndex = model[list].findIndex((item: string) => {
        return item === model[value];
      });

      if (currentIndex >= model[list].length - 1) {
        return;
      }

      model[value] = model[list][currentIndex + 1] as string;
    },
    emit() {
      this.$emit('update:modelValue', this.value);
    },
    deactivated() {
      const body = document.querySelector('body') as HTMLElement;

      body.classList.remove('cp-open-modal');
    },
  },
  watch: {
    defaultItems(newValue: number[]) {
      this.items = newValue;
    },
    current(value: string) {
      if (value === this.value) {
        return;
      }

      this.value = value;
      this.emit();
    },
    value() {
      this.emit();
    },
  },
  async mounted() {
    await this.initItems();

    document.querySelectorAll('.age-picker-component__hours .vue-scroll-picker-item').forEach((item: Element) => {
      item.addEventListener('click', () => {
        this.value = item.innerHTML;
      });
    });

    const body = document.querySelector('body') as HTMLElement;

    body.classList.add('cp-open-modal');

    this.emit();
  },
  async unmounted() {
    await this.deactivated();
  },
  async deactivated() {
    await this.deactivated();
  },
  components: {
    VueScrollPicker,
  },
});
